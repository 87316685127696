// Dependencies
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "@emotion/styled";

// Icon dependencies
import { BiMovie, BiBookHeart } from "react-icons/bi";
import { RiSpotifyFill } from "react-icons/ri";

// Styles
import colors from "styles/colors";
import dimensions from "styles/dimensions";

// Components
import Layout from "components/Layout";
import LoadingAnimation from "components/LoadingAnimation";

// Local images
import profilepic from "images/profile.jpeg";

// About container
const AboutSection = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2.5em;
    width: 100%;
    margin-top: 1em;
    
    @media (min-width: ${dimensions.minwidthXLscreen}px) {
        gap: 3em;
    }

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        flex-direction: column;
        gap: 2em;
    }

    // First header should have less margin
    h3:first-of-type {
        margin-top: 0em;
    }

    /* Styling links */
    a {
        text-decoration: none;
        transition: all 125ms ease-in-out; 
        color: ${colors.bqblue500};

        &:hover {
            cursor: pointer;
            transition: all 125ms ease-in-out;
            color: ${colors.bqblue600};    
            background-color: ${colors.bqblue200};
        }
    }
`

// Profile image
const ProfilePic = styled("div")`
    margin-top: 0.25em;
    width: 13em;

    @media (min-width: ${dimensions.minwidthXLscreen}px) {
        width: 13em;
    }

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 11em;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        width: 9em;
    }

    img {
        display: block;
        width: 100%;
        border-radius: 15px;
    }
`

// Text container
const AboutText = styled("div")`
    width: 70%;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 100%;
    }
`

// Korean text hover effect
const Korean = styled("span")`
    &:hover {
        cursor: help;
        
        span {
            display: none;
        }

        &:before {
            content: "한국어";
        }
    }
`

// Activities text
const Activities = styled("div")`
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .activity {
        display: flex; 
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;

        @media (max-width: 960px) {
            flex-direction: column;
        }

        @media (max-width: ${dimensions.maxwidthTablet}px) {
            flex-direction: row;
        }

        @media (max-width: ${dimensions.maxwidthMobile}px) {
            flex-direction: column;
        }

        .icon-verb {
            margin-right: 0.3em;

            .icon {
                color: ${colors.grey500};
                height: 100%;
                min-width: 20px;
                position: relative;
                top: 3px;

                @media (max-width: ${dimensions.maxwidthMobile}px) {
                    min-width: 15px;
                    top: 2px;
                }
            }

            // Verb
            span {
                font-weight: 700;
                color: ${colors.grey500};
                margin-left: 0.2em; 
            }
        }

        p {
            margin: 0;
        }
    }
`

// Creates one activity line with icon
const Activity = ({ icon, verb, title }) => (
    <div className="activity">
        <div className="icon-verb">
            {icon}
            <span>{verb}</span>
        </div>
        <p>{title}</p>
    </div>
);

const RenderBody = ({ meta }) => (
    <>
        <Helmet
            title={`About`}
            titleTemplate={`%s – ${meta.title}`}
            meta={[
                {
                    name: `description`,
                    content: meta.description,
                },
                {
                    property: `og:title`,
                    content: `About – ${meta.title}`,
                },
                {
                    property: `og:description`,
                    content: meta.description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: `About – ${meta.title}`,
                },
                {
                    name: `twitter:description`,
                    content: meta.description,
                },
            ].concat(meta)}
        />

        <LoadingAnimation>
            <AboutSection>

                <ProfilePic>
                    <img src={profilepic} alt="Bob Qian profile" />
                </ProfilePic>
                
                <AboutText>
                    <h3>It's nice to meet you!</h3>

                    <p>
                        I'm a fourth-year student at Duke University. I'm majoring in Computer Science, but I have an extensive background in design.
                    </p>

                    <p>
                        I'm from Nashville, Tennessee (although I don't listen to country music as often as I should). In my free time, you can find me learning <Korean><span>Korean</span></Korean>
                        , <a 
                            target="_blank"
                            rel="noreferrer"
                            href="https://youtube.com/bqianmusic">arranging sheet music</a>
                        , or finding new restaurants to try. 
                    </p>

                    <h3>What I'm up to</h3>

                    <Activities>
                        <Activity 
                            icon={<RiSpotifyFill className="icon" />}
                            verb="Listening to"
                            title="Refrigerator Door &mdash; Luke Combs"
                        /> 

                        <Activity 
                            icon={<BiBookHeart className="icon" />}
                            verb="Reading"
                            title="Sanshirō by Natsume Sōseki"
                        />

                        <Activity 
                            icon={<BiMovie className="icon" />}
                            verb="Watching"
                            title="Suits (2011)"
                        />
                    </Activities>
                </AboutText>

            </AboutSection>
        </LoadingAnimation>        
    </>
);

export default ({ data }) => {
    const meta = data.site.siteMetadata;
    
    return (
        <Layout>
            <RenderBody meta={meta}/>
        </Layout>
    )
}

// Ensure meta is object
RenderBody.propTypes = {
    meta: PropTypes.object.isRequired,
}

// GraphQL query
export const query = graphql`
    {
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`